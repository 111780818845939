
export default {
    PRODUCT_TAG: 'tag',
    PRODUCT_CATEGORY: 'category',
    PRODUCT: 'product',
    ST_CARD: 'st-card',
    BLACKLIST: 'blacklist',
    ACH_CARD: 'ach-card',
    COIN_CARD: 'coin-card',
    EDIT_ORDER_STATUS: 'order-status',
    CANCEL_ORDER_STATUS: 'order-cancel',
    CANCEL_CHARGE_STATUS: 'charge-cancel',
    CHARGE_REFUND_ALL: 'charge-refund-all',
};
