
import ActionConstants from '../constants/actions/ActionConstants';
// import * as PaymentAPI from '../apis/PaymentAPI';
import * as OrderAPI from '../apis/OrderAPI';
import * as NotifyAPI from '../apis/NotifyAPI';
import { showErrorGlobalAlert, showInfoGlobalAlert, showSuccessGlobalAlert } from './globalAlertActionCreator';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';
import moment from 'moment';

export const updateOrderList = (data) => ({
    type: ActionConstants.UPDATE_ORDER_LIST,
    payload: data,
});

export const updateFailedOrderList = (data) => ({
    type: ActionConstants.UPDATE_FAILED_ORDER_LIST,
    payload: data,
});


export const fetchOrderRecord = (params) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {

            OrderAPI.getOrderRecord(params)
            .then((res) => {
                if (res.status === 200 && res.data.code === 1) {
                    const ordertArr = res.data.data.list;
                    const total = res.data.data.total;
                    dispatch(updateOrderList({
                        data: ordertArr,
                        total
                    }));
                    return resolve('success');
                } else {
                    return reject(res.data.msg)
                }
            })
            .catch((err) => {
                dispatch(showErrorGlobalAlert('order-record-error'));
                return reject(err);
            });
        });
    };
};

export const informOrder = (id) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            NotifyAPI.notifyOrder(id)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(showInfoGlobalAlert('order-notify-succeed'));
                    return resolve('success');
                }
            })
            .catch((err) => {
                dispatch(showErrorGlobalAlert('order-notify-failed'));
            });
        });
    };
};

export const fetchOrderFailedRecord = (params, callback) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            
            OrderAPI.getOrderRecord(params)
            .then((res) => {
                if (res.status === 200) {
                    const ordertArr = res.data.data.list;
                    const total = res.data.data.total;
                    dispatch(updateFailedOrderList({
                        data: ordertArr,
                        total
                    }));
                    callback && callback();
                }
            })
            .catch((err) => {
                dispatch(showErrorGlobalAlert('order-record-error'));
            });
        });
    };
};

export const getOrderDetail = (orderNo) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {

            OrderAPI.getOrderDetail({orderNo})
            .then((res) => {
                if (res.status === 200 && res.data.code === 1) {
                    return resolve(res.data.data);
                } else {
                    return reject(res.data.msg)
                }
            })
            .catch((err) => {
                dispatch(showErrorGlobalAlert('order-record-error'));
                return reject(err);
            });
        });
    };
};

export const updateOrderPaymentStatus = (id) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            OrderAPI.updateOrderPaymentStatus({id, status: 1})
            .then((res) => {
                if (res.status === 200) {
                    dispatch(showInfoGlobalAlert('order-payment-status-update-succeed'));
                    return resolve('success');
                }
            })
            .catch((err) => {
                dispatch(showErrorGlobalAlert('order-payment-status-update-failed'));
            });
        });
    };
};

export const cancelOrderSubscription = (record) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            OrderAPI.cancelOrderSubscription({
                client_orderNo: record.client_orderNo,
                url: record.url,
            })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(showInfoGlobalAlert('order-cancel-succeed'));
                    return resolve('success');
                }
            })
            .catch((err) => {
                dispatch(showErrorGlobalAlert('order-cancel-failed'));
            });
        });
    };
};

export const retrieveOrderRiskInfo = (id) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            OrderAPI.retrieveOrderRiskInfo({id})
            .then((res) => {
                if (res.status === 200 && res.data.data.charges) {
                    return resolve(res.data.data);
                }
                dispatch(showErrorGlobalAlert('risk-fetch-failed'));
                return reject('err');
            })
            .catch((err) => {
                dispatch(showErrorGlobalAlert('order-cancel-failed'));
                return reject(err);
            });
        });
    };
};

export const cancelSTChargeOrder = (record) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            OrderAPI.cancelSTChargeOrder({
                id: record.id,
                charge: record.charge,
            })
            .then((res) => {
                if (res.status === 200 && res.data.code === 1 && res.data.data.chargesRs && res.data.data.chargesRs.status === 'succeeded') {
                    dispatch(showInfoGlobalAlert('charge-cancel-succeed'));
                    return resolve('success');
                } else {
                    dispatch(showErrorGlobalAlert('null', res.data.data.chargesRs.error.message));
                    reject('err');
                }
            })
            .catch((err) => {
                dispatch(showErrorGlobalAlert('charge-cancel-failed'));
            });
        });
    };
};

export const fetchOrderInvoices = (params) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            OrderAPI.fetchOrderInvoices(params)
            .then((res) => {
                if (res.status === 200 && res.data.code === 1) {
                    const record = res.data.data.data.data;
                    if (record.length > 0) {
                        return resolve(res.data.data);
                    }
                }
            })
            .catch((err) => {
                dispatch(showErrorGlobalAlert('order-invoices-failed'));
            });
        });
    };
};

export const refundAllCharge = (orderNu) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            OrderAPI.refundAllChargeOrder({
                client_orderNo: orderNu,
            })
            .then((res) => {
                if (res.status === 200 && res.data.code === 1 && res.data.msg === 'success') {
                    dispatch(showInfoGlobalAlert('refund-all-charge-succeed'));
                    return resolve('success');
                } else {
                    dispatch(showErrorGlobalAlert('null', res.data.msg));
                    reject('err');
                }
            })
            .catch((err) => {
                dispatch(showErrorGlobalAlert('refund-all-charge-failed'));
            });
        });
    };
};