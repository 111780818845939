export default {
    
    GLOBAL_ALERT                               : 'globalAlert',

    CURRENT_USER                               : 'current_user',
    AUTHENTICATION                             : 'authentication',
    LOGIN_STATUS                               : 'login_status',
    PAYMENT_TYPE                               : 'payment_type',
    USER_LIST                                  : 'user_list',
    USER_TOTAL_COUNT                           : 'user_total_count',


    FILTER_TIME                                : 'filter_time',
    FILTER_START_DATE                          : 'filter_start_date',
    FILTER_END_DATE                            : 'filter_end_date',


    ORDER_GRAPH                                : 'ORDER_GRAPH',
    SUCCESSFUL_ORDER_GRAPH                     : 'SUCCESSFUL_ORDER_GRAPH',
    SUCCESSFUL_ORDER_NUM_GRAPH                 : 'SUCCESSFUL_ORDER_NUM_GRAPH',
    FAILED_ORDER_NUM_GRAPH                     : 'FAILED_ORDER_NUM_GRAPH',
    FIALED_ORDER_GRAPH                         : 'FIALED_ORDER_GRAPH',

    WITHDRAW_AMOUNT_GRAPH                      : 'WITHDRAW_AMOUNT_GRAPH',

    ORDER_STATISTICS_DATA                      : 'ORDER_STATISTICS_DATA',
    
    TOTAL_AMOUNT                               : 'total_amount',
    TOTAL_AMOUNT_LIST                          : 'total_amount_list',
    TOTAL_AMOUNT_NUM                           : 'total_amount_num',

    EXPENDITURE                               : 'expenditure',
    EXPENDITURE_LIST                          : 'expenditure_list',
    EXPENDITURE_NUM                           : 'expenditure_num',

    // WITHDRAW                                  : 'withdraw',
    // WITHDRAW_LIST                             : 'withdraw_list',
    // WITHDRAW_NUM                              : 'withdraw_num',




    ORDER_LIST                                : 'order_list',
    FAILED_ORDER_LIST                         : 'failed_order_list',
    ORDER_TOTAL_COUNT                         : 'order_total_count',

    WITHDRAW_LIST                              : 'withdraw_list',
    WITHDRAW_TOTAL_COUNT                       : 'withdraw_total_count',
    MONEY_BALANCE                              : 'money_balance',

    ST_CARD_LIST                               : 'st_card_list',
    ST_CARD_TOTAL_COUNT                        : 'st_card_total_count',

    BLACKLIST                                  : 'blacklist',
    BLACKLIST_TOTAL_COUNT                      : 'blacklist_total_count',

    ST_GROUP_LIST                              : 'st_group_list',
    ST_GROUP_TOTAL_COUNT                       : 'st_group_total_count',

    IBAN_CARD_LIST                             : 'iban_card_list',
    IBAN_CARD_TOTAL_COUNT                      : 'iban_card_total_count',

    ACH_CARD_LIST                              : 'ach_card_list',
    ACH_CARD_TOTAL_COUNT                       : 'ach_card_total_count',

    COIN_LIST                                  : 'coin_list',
    COIN_TOTAL_COUNT                           : 'coin_total_count',

    ROUTER_LIST                                : 'router_list',


    // =========================================
    TAG_LIST                                   : 'tag_list',
    TAG_TOTAL_COUNT                            : 'tag_total_count',

    CATEGORY_LIST                              : 'category_list',
    CATEGORY_TOTAL_COUNT                       : 'category_total_count',

    PRODUCT_LIST                               : 'product_list',
    PRODUCT_TOTAL_COUNT                        : 'product_total_count',

    PAYMENT_LIST                               : 'payment_list',
    PAYMENT_TOTAL_COUNT                        : 'payment_total_count',
  };
  

export const LOGIN_CONSTANTS = {
  LOGGED_IN                         : 'LOGGED_IN',
  LOGGED_OUT                        : 'LOGGED_OUT',
}

export const PAYMENT_TYPE_CONSTANTS = {
  STRIPE                         : 'Stripe',
  IBAN                           : 'Iban',
}