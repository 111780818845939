import { Map } from 'immutable';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ActionConstants from '../constants/actions/ActionConstants';
import I18N from '../i18n';
import moment from 'moment';


const initialState = Map({
  [StoreStateConstants.ORDER_GRAPH]: Map({
    [StoreStateConstants.SUCCESSFUL_ORDER_GRAPH]: null,
    [StoreStateConstants.FIALED_ORDER_GRAPH]: null,
    [StoreStateConstants.SUCCESSFUL_ORDER_NUM_GRAPH]: null,
  }),
  [StoreStateConstants.WITHDRAW_AMOUNT_GRAPH]: null,
  [StoreStateConstants.ORDER_STATISTICS_DATA]: null,
});

export const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionConstants.UPDATE_SUCCESSFUL_ORDER_GRAPH: {
      return state.setIn([StoreStateConstants.ORDER_GRAPH, StoreStateConstants.SUCCESSFUL_ORDER_GRAPH], action.payload.data);
    }
    case ActionConstants.UPDATE_FAILED_ORDER_GRAPH: {
      return state.setIn([StoreStateConstants.ORDER_GRAPH, StoreStateConstants.FIALED_ORDER_GRAPH], action.payload.data);
    }
    case ActionConstants.UPDATE_WITHDRAW_AMOUNT_GRAPH: {
      return state.set(StoreStateConstants.WITHDRAW_AMOUNT_GRAPH, action.payload.data);
    }
    case ActionConstants.UPDATE_SUCCESSFUL_ORDER_NUM_GRAPH: {
      return state.setIn([StoreStateConstants.ORDER_GRAPH, StoreStateConstants.SUCCESSFUL_ORDER_NUM_GRAPH], action.payload.data);
    }
    case ActionConstants.UPDATE_FAILED_ORDER_NUM_GRAPH: {
      return state.setIn([StoreStateConstants.ORDER_GRAPH, StoreStateConstants.FAILED_ORDER_NUM_GRAPH], action.payload.data);
    }
    case ActionConstants.UPDATE_ORDER_STATISTICS_DATA: {
      return state.set(StoreStateConstants.ORDER_STATISTICS_DATA, action.payload);
    }
    default:
      return state;
  }
};
