import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from "query-string";

// --- Components --- //
import SvgIcon from '../../components/common/SvgIcon';
import Loading from '../../components/common/LoadingIndicator';
import DeleteConfirmationModal from '../../components/common/dialog/DeleteConfirmationModal';

// --- Utils --- //
import * as DateTimeUtil from '../../utils/DateTimeUtil';

// --- Constants --- //
import PagePathConstants from '../../constants/router/PagePathConstants';
import ColorConstants from '../../constants/ColorConstants';
import DialogConstants from '../../constants/dialog/index';

// --- Actions --- //
import * as stCardActionCreator from '../../actions/stCardActionCreator';
import * as stGroupActionCreator from '../../actions/stGroupActionCreator';
import * as orderActionCreator from '../../actions/orderActionCreator';
import * as globalAlertActionCreator from '../../actions/globalAlertActionCreator';

import I18N from '../../i18n';
import CheckIcon from '../../icons/CheckIcon';
import CheckFailedIcon from '../../icons/CheckFailedIcon';
import classNames from 'classnames';
import GlobalAlertConstants from '../../constants/GlobalAlertConstants';
import RDSButton from '../../components/common/RDSButton';

class OrderDetailPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            showLoading: false,
            showRefundAllModal: false,
            showRefundLoading: false,
            orderNo: '',
            // product: '',
            // productSku: '',
            // num: '',
            amount: '',
            username: '',
            email: '',
            phone: '',
            address: '',
            zipCode: '',
            orderTime: '',
            ad: '',

            invoiceData: null,
            showCancelConfirmModal: false,
            cancelCharge: null,
            stCardId: null,
            failure_detail: null
        };

        this.toggleLoading = this.toggleLoading.bind(this);
        this.togglePageLoading = this.togglePageLoading.bind(this);
        this.getOrderInfoById = this.getOrderInfoById.bind(this);
        this.handleBackPage = this.handleBackPage.bind(this);
        this.fetchOrderInvoices = this.fetchOrderInvoices.bind(this);
        this.handleCancelCharge = this.handleCancelCharge.bind(this);
        this.toggleCancelConfirmModal = this.toggleCancelConfirmModal.bind(this);
        this.handleCancelChargeOrder = this.handleCancelChargeOrder.bind(this);
        this.handleConfirmRefundAll = this.handleConfirmRefundAll.bind(this);
        this.toggleRefundAllConfirmModal = this.toggleRefundAllConfirmModal.bind(this);
        this.handleRefundAll = this.handleRefundAll.bind(this);
    }

    componentDidMount() {
        this.getOrderInfoById();

    }

    fetchOrderInvoices(params) {
        const { orderActions } = this.props;
        orderActions && orderActions.fetchOrderInvoices(params)
            .then(data => {
                const refundList = data.tuikuan;
                let invoiceList = data.data.data;
                invoiceList = invoiceList.map(item => {
                    item['customize_refund_status'] = false;
                    const charge = item.charge;
                    const filterArr = refundList.filter(item => item.charge === charge);
                    if (filterArr.length > 0) {
                        const refundSucceedRecord = filterArr.filter(refundRecord => refundRecord.status === '退款成功');
                        if (refundSucceedRecord.length > 0) {
                            item['customize_refund_status'] = true;
                        }
                    }
                    return item;
                });
                this.setState({ invoiceData: invoiceList });
            })
    }

    getOrderInfoById() {
        const { location, orderActions, globalAlertActions } = this.props;
        const orderId = location.pathname.split(`${PagePathConstants.ORDER_PAGE}/`)[1];

        const queryParams = queryString.parse(location.search);

        if (orderId && orderId !== '') {
            this.toggleLoading();
            orderActions.getOrderDetail(orderId)
                .then(orderInfo => {

                    if (orderInfo.failure_code && orderInfo.failure_code !== '' && orderInfo.failure_code !== undefined) {
                        this.setState({
                            failure_detail: {
                                failure_code: orderInfo.failure_code,
                                failure_message: orderInfo.failure_message,
                                seller_message: orderInfo.seller_message
                            }
                        });
                    }

                    if (queryParams.cSiteUrl && queryParams.cSiteUrl.indexOf('http') < 0) {
                        globalAlertActions && globalAlertActions.dispatchGlobalAlert('st-card-deleted', 'The ST account to which this order belonged has been deleted, so the invoice list cannot be checked.', GlobalAlertConstants.ERROR);
                    } else {
                        this.fetchOrderInvoices({
                            client_orderNo: orderInfo.orderNu,
                            url: orderInfo.url,
                            email: orderInfo.email,
                            c_site_url: queryParams.cSiteUrl,
                        });
                    }

                    let productImg = '';
                    // 用于处理图片数据不合规的问题，以前没有]，不是完整的json 数据
                    // if (orderInfo && orderInfo.goodsList && orderInfo.goodsList[0] && orderInfo.goodsList[0].goods_image.indexOf(']') > 0) {
                    //     productImg = orderInfo && orderInfo.goodsList && orderInfo.goodsList[0] && orderInfo.goodsList[0].goods_image && (window.location.protocol + "//" +  orderInfo.url + JSON.parse(orderInfo.goodsList[0].goods_image)[0]);
                    // } else {
                    //     productImg = orderInfo && orderInfo.goodsList && orderInfo.goodsList[0] && orderInfo.goodsList[0].goods_image && (window.location.protocol + "//" +  orderInfo.url + JSON.parse(orderInfo.goodsList[0].goods_image + ']')[0]);
                    // }
                    // const productTitle = orderInfo && orderInfo.goodsList && orderInfo.goodsList[0] && orderInfo.goodsList[0].goods_title;
                    // const selectedSku = orderInfo && orderInfo.goodsList && orderInfo.goodsList[0] && orderInfo.goodsList[0].goods_image && JSON.parse(orderInfo.goodsList[0].goods_sku);
                    // let formatSku = '';
                    // selectedSku.map(item => {
                    //     formatSku += `<span class="tw-mr-[15px]">${item.title}: ${item.content}</span>`;
                    // });
                    // const num = orderInfo && orderInfo.goodsList && orderInfo.goodsList[0] && orderInfo.goodsList[0].goods_num;

                    this.setState({
                        stCardId: orderInfo.type_aisle,
                        orderNo: orderInfo.orderNu,
                        // product: productImg,
                        // productTitle: productTitle,
                        // productSku: formatSku,
                        // num: num,
                        amount: orderInfo.amount,
                        username: orderInfo && `${orderInfo.firstName}`,
                        email: orderInfo && orderInfo.email,
                        phone: orderInfo && orderInfo.phone,
                        address: orderInfo && `${orderInfo.state} ${orderInfo.city} ${orderInfo.address}`,
                        zipCode: orderInfo && orderInfo.zipCode,
                        orderTime: orderInfo && DateTimeUtil.showFullTimeFormat(orderInfo.updatetime * 1000),
                        ad: orderInfo && orderInfo.ad,
                    });
                    this.toggleLoading();
                })
                .catch(() => {
                    this.toggleLoading();
                })
        }
    }

    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }

    togglePageLoading() {
        this.setState({ showRefundLoading: !this.state.showRefundLoading });
    }

    handleBackPage() {
        const { history } = this.props;
        history && history.goBack();
    }

    handleCancelCharge(record) {
        if (!record.paid || !record.charge || record.customize_refund_status) return;
        this.toggleCancelConfirmModal();
        this.setState({ cancelCharge: record });
    }

    toggleCancelConfirmModal() {
        this.setState({ showCancelConfirmModal: !this.state.showCancelConfirmModal });
    }

    handleCancelChargeOrder() {
        const { orderActions } = this.props;
        const { cancelCharge, stCardId } = this.state;
        stCardId && orderActions && (cancelCharge && cancelCharge !== '') && orderActions.cancelSTChargeOrder({ id: stCardId, charge: cancelCharge.charge })
            .then(data => {
                this.toggleCancelConfirmModal();
                return;
            })
            .catch(this.toggleCancelConfirmModal);
    }

    handleConfirmRefundAll() {
        this.toggleRefundAllConfirmModal();
    }

    toggleRefundAllConfirmModal() {
        this.setState({ showRefundAllModal: !this.state.showRefundAllModal });
    }

    handleRefundAll() {
        const { location, orderActions } = this.props;
        const orderNu = location.pathname.split(`${PagePathConstants.ORDER_PAGE}/`)[1];
        this.togglePageLoading();
        orderActions && orderActions.refundAllCharge(orderNu)
            .then(data => {
                this.togglePageLoading();
                this.toggleRefundAllConfirmModal();
                this.getOrderInfoById();
                return;
            })
            .catch(() => {
                this.togglePageLoading();
                this.toggleRefundAllConfirmModal();
            });
    }

    render() {
        const { showLoading, orderNo, num, amount, username, email, phone, address, zipCode, orderTime, ad, invoiceData, showCancelConfirmModal, showRefundAllModal, failure_detail, showRefundLoading } = this.state;
        return <div className='tw-w-full tw-px-[50px] tw-py-[50px]'>
            {showLoading && <Loading />}
            <DeleteConfirmationModal isDeleteModalOpen={showCancelConfirmModal} toggle={this.toggleCancelConfirmModal} handleDeleteConfirm={this.handleCancelChargeOrder} sectionName={DialogConstants.CANCEL_CHARGE_STATUS} />
            <DeleteConfirmationModal isDeleteModalOpen={showRefundAllModal} toggle={this.toggleRefundAllConfirmModal} handleDeleteConfirm={this.handleRefundAll} sectionName={DialogConstants.CHARGE_REFUND_ALL} loading={showRefundLoading} />
            <div className='tw-rounded-[30px] tw-bg-white'>
                <div className='tw-flex tw-items-center  tw-px-[30px] tw-py-[24px] tw-border-b' onClick={this.handleBackPage}>
                    <SvgIcon name="rds-back-icon" color={ColorConstants.black[0]} size={24} />
                    <span className='tw-ml-[10px] tw-text-[18px] tw-font-bold' >{I18N.getText('back')}</span>
                </div>
                <div className='tw-text-[14px] tw-px-[30px] tw-py-[30px]'>
                    <div className='tw-flex tw-items-center'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('order-no')}</div>
                        <div className='tw-w-[80%] tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{orderNo}</div>
                    </div>
                    {/* <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('product')}</div>
                        <div className='tw-w-[80px] tw-h-[80px] ' style={{ backgroundImage: `url(${product})`, backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}></div>
                    </div>

                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('product-title')}</div>
                        <div className='tw-w-[80%] tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{productTitle}</div>
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('specification')}</div>
                        <div className='tw-w-[80%] '><div className=' tw-flex phone:tw-flex-col tw-items-start' dangerouslySetInnerHTML={{__html: productSku}}></div></div>
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('num')}</div>
                        <div className='tw-w-[80%] tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{num}</div>
                    </div> */}
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('amount')}</div>
                        <div className='tw-w-[80%] tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{amount}</div>
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('username')}</div>
                        <div className='tw-w-[80%] tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{username}</div>
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('email')}</div>
                        <div className='tw-w-[80%] tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{email}</div>
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('phone')}</div>
                        <div className='tw-w-[80%] tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{phone}</div>
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('address')}</div>
                        <div className='tw-w-[80%] tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{address}</div>
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('ZipCode')}</div>
                        <div className='tw-w-[80%] tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{zipCode}</div>
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('order-time')}</div>
                        <div className='tw-w-[80%] tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{orderTime}</div>
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('ad')}</div>
                        <div className='tw-w-[80%] tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{ad}</div>
                    </div>

                    {failure_detail && <div className='tw-mt-[20px] tw-bg-[#ffe7f26e] tw-px-[15px] tw-py-[10px] tw-rounded-[10px]'>
                        <pre className='hs_error_message_pre'>
                            {JSON.stringify(failure_detail, num, 4)}
                        </pre>
                    </div>}
                </div>

                {invoiceData && <div className='tw-mt-[20px] tw-bg-white tw-rounded-[30px]'>
                    <div className='tw-w-full tw-h-auto tw-rounded-[30px]'>
                        <div className='tw-w-full tw-flex tw-justify-between tw-items-center tw-border-b tw-border-[rgb(233, 233, 239)] tw-px-[50px]'>
                            <div className='tw-flex tw-items-center tw-font-bold tw-text-[20px] tw-h-[70px]'>Order invoice list</div>
                            <RDSButton onClick={this.handleConfirmRefundAll} color="primary" className="tw-flex tw-items-center tw-justify-center tw-text-white">
                                <SvgIcon size={16} name="cancel-order-icon" className="tw-mr-[10px]" color="white" /> Full Refund
                            </RDSButton>
                        </div>
                        <div className='tw-w-full tw-py-[20px] tw-px-[20px]'>
                            <div className='tw-flex tw-items-center tw-text-center tw-font-bold tw-text-[12px] tw-mb-[10px] tw-h-[40px]  tw-rounded-[10px] tw-shadow-table tw-text-[14px] tw-font-bold'>
                                <div className='tw-w-[5%] tw-text-center'>#</div>
                                <div className='tw-w-[10%] tw-text-center'>Paid</div>
                                <div className='tw-w-[10%] tw-text-center'>Currency</div>
                                <div className='tw-w-[10%] tw-text-center'>Amount Due</div>
                                <div className='tw-w-[10%] tw-text-center'>Amount Paid</div>
                                <div className='tw-w-[18%] tw-text-center'>Charge ID</div>
                                <div className='tw-w-[18%] tw-text-center'>Payment Intent ID</div>
                                <div className='tw-w-[10%] tw-text-center'>{I18N.getText('date')}</div>
                                <div className='tw-w-[9%] tw-text-center'>{I18N.getText('operation')}</div>
                            </div>
                            {invoiceData && invoiceData.map((item, index) => {
                                return (<div className='tw-w-full tw-text-[12px] tw-pb-[10px] tw-shadow-table tw-flex tw-items-center  tw-mt-[20px] tw-border-b tw-border-[rgb(233, 233, 239)]'>
                                    <div className='tw-w-[5%] tw-text-center'>{index + 1}</div>
                                    <div className='tw-w-[10%] tw-text-center tw-flex tw-justify-center'>{item.paid ? <CheckIcon size={20} color='#3EE0B9' /> : <CheckFailedIcon color='#FF5C66' size={20} />}</div>
                                    <div className='tw-w-[10%] tw-text-center'>{item.currency}</div>
                                    <div className='tw-w-[10%] tw-text-center tw-flex tw-justify-center'>{item.amount_due}</div>
                                    <div className='tw-w-[10%] tw-text-center tw-flex tw-justify-center'>{item.amount_paid}</div>
                                    <div className='tw-w-[18%] tw-text-center'>{item.charge}</div>
                                    <div className='tw-w-[18%] tw-text-center'>{item.payment_intent}</div>
                                    <div className='tw-w-[10%] tw-text-center'>{item.status_transitions && item.status_transitions.finalized_at && DateTimeUtil.showFullTimeFormat(item.status_transitions.finalized_at * 1000)}</div>
                                    <div className='tw-w-[9%] tw-flex tw-justify-center tw-items-center'>
                                        <SvgIcon className={classNames('', { 'disabled-operation': !item.paid || !item.charge || item.customize_refund_status })} size={18} color={item.paid && item.charge ? (!item.customize_refund_status ? ColorConstants.brand.primaryLight : ColorConstants.nav.inactive) : ColorConstants.nav.inactive} name="cancel-order-icon" onClick={() => this.handleCancelCharge(item)} />
                                    </div>

                                </div>)
                            })}
                        </div>

                    </div>
                </div>}
            </div>
        </div>;
    }
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
        orderActions: bindActionCreators(stCardActionCreator, dispatch),
        stGroupActions: bindActionCreators(stGroupActionCreator, dispatch),
        orderActions: bindActionCreators(orderActionCreator, dispatch),
        globalAlertActions: bindActionCreators(globalAlertActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailPage);